<template>
    <div class="content">
        <h3>催告上班通知书</h3>
        <article>
            <p>{{ detail.real_name }}：（身份证号码 {{ detail.idcard }} ）</p>
            <p>
                您于<input
                    :value="detail.column1 && detail.column1.year"
                />年<input
                    :value="detail.column1 && detail.column1.month"
                />月<input
                    :value="detail.column1 && detail.column1.day"
                />日至<input
                    :value="detail.column2 && detail.column2.year"
                />年<input
                    :value="detail.column2 && detail.column2.month"
                />月<input
                    :value="detail.column2 && detail.column2.day"
                />日，未按要求办理请假手续，已连续旷工<input
                    :value="detail.absenteeism"
                />
                天。请于<input
                    :value="detail.column3 && detail.column3.year"
                />年<input
                    :value="detail.column3 && detail.column3.month"
                />月<input
                    :value="detail.column3 && detail.column3.day"
                />日前主动与公司人力资源部门联系。逾期不到，公司会根据规章制度相关规定按自动离职处理，公司财务于
                <input :value="detail.column4 && detail.column4.year" />年<input
                    :value="detail.column4 && detail.column4.month"
                />月<input
                    :value="detail.column4 && detail.column4.day"
                />日将与您结清工资，到公司前请与公司人力资源部门联系。您旷工期间一切行为与公司无关，造成的一切后果由您个人承担！
            </p>
            <p>特此通知！</p>
            <p class="p_right">
                {{ detail.company_name }}<br /><input
                    :value="detail.sign_date && detail.sign_date.year"
                />年<input
                    :value="detail.sign_date && detail.sign_date.month"
                />月<input
                    :value="detail.sign_date && detail.sign_date.day"
                />日
            </p>
        </article>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            detail: {},
        };
    },
    props: ["id"],
    created() {
        let loading = this.$loading();
        this.getReminderNoticeDetail({ id: this.id })
            .then((res) => {
                console.log(res);
                this.detail = res.data.data.detail;
                loading.close();
            })
            .catch((e) => {
                console.log(e);
                loading.close();
                this.$message.error(e.msg);
            });
    },
    methods: {
        ...mapActions("employee", ["getReminderNoticeDetail"]),
    },
};
</script>

<style lang="scss" scoped>
.content {
    font-size: 24px;
    padding: 50px 100px;
    > h3 {
        text-align: center;
    }

    p {
        line-height: 60px;
        text-indent: 2em;
    }

    .p_right {
        text-align: right;
    }
}

input {
    width: 100px;
    background: none;
    outline: none;
    border: 0px;
    border-bottom: 1px solid black;
    font-size: 24px;
    text-align: center;
}
</style>
